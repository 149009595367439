import React, { createContext, useState } from 'react';
import { MODALS, MODALS_COMP } from 'src/hooks/useGlobalModal';

type GlobalModalContext = {
  openModal: (modalType: MODALS, extra?) => void;
  modalNotify: any;
  modalState: boolean;
};

const initalState: GlobalModalContext = {
  openModal: () => void 0,
  modalNotify: null,
  modalState: false
};

const GlobalModalContext = createContext(initalState);

export const GlobalModalProvider: React.FC<{}> = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const [modalType, setModalType] = useState<MODALS>();
  const [extra, setExtra] = useState();
  const [notify, setNotify] = useState(null);

  const cancelHandler = () => setVisible(false);

  const openModal = (modalType: MODALS, extra = null) => {
    setModalType(modalType);
    setExtra(extra);
    setVisible(true);
  };

  const setNotifyHandler = (data) => {
    setNotify({ ...data, modalType });
    setTimeout(() => {
      setNotify(null);
    }, 1000);
  };

  const renderComponent = ({ visible, cancel, setNotify }) => {
    const ModalComponent = MODALS_COMP[modalType];
    if (typeof modalType === undefined || !ModalComponent) return null;
    return <ModalComponent visible={visible} cancel={cancel} extra={extra} setNotify={setNotify} />;
  };

  return (
    <GlobalModalContext.Provider value={{ openModal, modalState: visible, modalNotify: notify }}>
      {renderComponent({ visible, cancel: cancelHandler, setNotify: setNotifyHandler })}
      {children}
    </GlobalModalContext.Provider>
  );
};

export default GlobalModalContext;
