import React, { FC, useCallback, useEffect, useState } from 'react';
import DialogAdvanced from 'src/components/DialogAdvanced';
import useAuth from 'src/hooks/useAuth';
import api from 'src/lib/api';
import { Email } from 'src/types/email';
import { USER_ROLES } from 'src/types/user';

import { Grid, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import EmailEditForm from './EmailEditForm';

const allLabels = {
  pageTitle: 'Trimite email',
  messages: {
    success: 'Email trimis cu succes!',
    fail: 'Problema trimitere email'
  },
  cardTitle: 'Trimite email',
  submitLabel: 'Trimite'
};

interface Props {
  cancel: () => void;
  setNotify: (data?: unknown) => void;
  visible: boolean;
  extra?: { email: Email; documentId?: string; additionId?: string };
}

const EmailModal: FC<Props> = ({ extra, ...rest }) => {
  const { role } = useAuth();
  const [attachment, setAttachment] = useState<{ loading: boolean; value: string }>({ loading: false, value: null });

  // const getFile = useCallback(async () => {
  // if (extra.documentId) {
  //   return await api.documents.getFile({ documentId: extra?.documentId });
  // }
  // }, [extra]);

  const getAttachment = useCallback(async () => {
    if (extra?.email?.attachment) {
      setAttachment({ loading: false, value: extra.email.attachment });
    } else if (extra?.documentId || extra?.additionId) {
      try {
        setAttachment({ loading: true, value: null });
        // const file = await getFile();
        // setAttachment({ loading: false, value: file.id });
      } catch (err) {
        setAttachment({ loading: false, value: null });
      }
    }
  }, [extra]);

  useEffect(() => {
    if (rest.visible) {
      getAttachment();
    }
  }, [rest.visible, getAttachment]);

  const saveHandler = async (entity: Email) => {
    if (attachment.value) entity.attachment = attachment.value;
    const email = await api.emails.create(entity);
    rest.setNotify(email);
    rest.cancel();
    return email;
  };

  return (
    <DialogAdvanced {...rest}>
      <Grid container>
        <Grid item xs={12}>
          <EmailEditForm
            entity={extra?.email}
            attachment={attachment.value}
            attachmentLoading={attachment.loading}
            action={saveHandler}
            labels={allLabels}
            freezed={role === USER_ROLES.Member}
            cardHeaderAction={
              <IconButton aria-label="close" onClick={rest.cancel}>
                <CloseIcon />
              </IconButton>
            }
          />
        </Grid>
      </Grid>
    </DialogAdvanced>
  );
};

export default EmailModal;
