import clsx from 'clsx';
import React, { FC, ReactNode, useState } from 'react';
import { Theme } from 'src/theme';

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';

interface DialogProps {
  visible: boolean;
  title: string;
  question: string;
  actionTitle: string;
  customActionIcon?: ReactNode;
  customActionStyle?: string;
  cancel: (event: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void;
  action: (event: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  cancel: {
    color: theme.palette.primary.main
  },
  action: {
    color: theme.palette.error.main
  },
  pending: {
    width: 93,
    display: 'flex',
    justifyContent: 'center'
  }
}));

const CustomDialog: FC<DialogProps> = ({
  visible,
  title,
  question,
  actionTitle,
  customActionIcon,
  customActionStyle,
  cancel,
  action
}) => {
  const classes = useStyles();

  const [actionPending, setActionPending] = useState(false);

  const customAction = async (event) => {
    setActionPending(true);
    await action(event);
    setActionPending(false);
  };

  return (
    <Dialog
      open={visible}
      onClose={cancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{question}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel} startIcon={<CloseIcon />} className={classes.cancel}>
          Anuleaza
        </Button>
        {actionPending ? (
          <Box className={classes.pending}>
            <CircularProgress size={24} className={classes.action} />
          </Box>
        ) : (
          <Button
            onClick={customAction}
            startIcon={customActionIcon || <DeleteIcon />}
            className={clsx(classes.action, customActionStyle)}
          >
            {actionTitle}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
