import clsx, { ClassValue } from 'clsx';
import React, { FC, ReactElement, ReactNode } from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

interface CustomButtonProps {
  label?: string;
  loading?: boolean;
  success?: boolean;
  disabled?: boolean;
  variant?: 'text' | 'outlined' | 'contained';
  color?: 'inherit' | 'primary' | 'secondary' | 'default';
  className?: string;
  icon?: ReactElement;
  children?: ReactNode;
  [key: string]: any;
}

const useStyles = makeStyles((theme) => ({
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700]
    }
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  labelProgress: {
    visibility: 'hidden'
  }
}));

const CustomButton: FC<CustomButtonProps> = ({
  label,
  loading,
  success,
  disabled,
  variant,
  color,
  className,
  icon,
  children,
  ...rest
}) => {
  const classes = useStyles();
  const buttonClasses: ClassValue[] = [
    {
      [classes.buttonSuccess]: success
    }
  ];
  if (className) buttonClasses.push(className);
  const buttonClassname = clsx(buttonClasses);
  const labelClassname = clsx({
    [classes.labelProgress]: loading
  });
  return (
    <Button
      variant={variant || 'contained'}
      color={color || 'secondary'}
      className={buttonClassname}
      disabled={loading || disabled}
      size="small"
      startIcon={icon}
      {...rest}
    >
      <span className={labelClassname}>
        {label}
        {children}
      </span>
      {loading && (
        <div>
          <CircularProgress size={24} className={classes.buttonProgress} />
        </div>
      )}
    </Button>
  );
};

export default CustomButton;
