import axios from 'axios';

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const parsedError = {
      code: error?.response?.data?.code ?? 5000,
      name: error?.response?.data?.name ?? 'ServerError',
      message: error?.response?.data?.message ?? 'Something went wrong :(',
      field: 'submit'
    };
    if (error?.response?.data?.errors?.length) parsedError.message += ` Reason: ${error.response.data.errors[0]}`;
    switch (parsedError.code) {
      case 4003:
        parsedError.field = 'email';
        break;
      case 4004:
        parsedError.field = 'phone';
        break;
      case 4009:
        parsedError.field = 'name';
        break;
      case 4010:
        parsedError.field = 'cui';
        break;
      case 5000:
        if (error?.response?.data) {
          parsedError.message = JSON.stringify(error.response.data);
        } else if (error?.message) {
          parsedError.message = error.message;
        }
    }
    return Promise.reject(parsedError);
  }
);

export default axiosInstance;
