import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Dialog from 'src/components/Dialog';
import { API_SUFFIX } from 'src/lib/api';
import { UploadedFile } from 'src/types/file';
import bytesToSize from 'src/utils/bytesToSize';
import formatDateUtil from 'src/utils/formatDate';

import {
    Box, CircularProgress, IconButton, Link, List, ListItem, ListItemIcon, ListItemText, makeStyles,
    SvgIcon, Tooltip, Typography
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import type { FC } from 'react';
import type { Theme } from 'src/theme';
interface FilesDropzoneProps {
  values: UploadedFile[];
  attachments?: UploadedFile[];
  className?: string;
  multiple?: boolean;
  isDisabled?: boolean;
  loading?: boolean;
  onDrop: (files: any) => void;
  onDelete?: (index: number, fileId: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  dropZone: {
    position: 'relative',
    border: `1px dashed ${theme.palette.divider}`,
    padding: theme.spacing(2),
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      opacity: 0.5,
      cursor: 'pointer'
    }
  },
  loading: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)'
  },
  disabled: {
    backgroundColor: theme.palette.action.hover,
    opacity: 0.5,
    '&:hover': {
      cursor: 'default'
    }
  },
  dragActive: {
    backgroundColor: theme.palette.action.active,
    opacity: 0.5
  },
  image: {
    width: 80,
    margin: theme.spacing(1)
  },
  info: {
    marginTop: theme.spacing(1)
  },
  list: {
    maxHeight: 500
  },
  actions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  },
  redirectText: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

const labels = {
  single: { title: 'Alegeti fisierul', description: 'Incarcati fisierul prin drag-and-drop' },
  multi: { title: 'Alegeti fisierele', description: 'Incarcati fisierele prin drag-and-drop' }
};

const FilesDropzone: FC<FilesDropzoneProps> = ({
  onDrop,
  onDelete,
  multiple,
  values,
  attachments,
  isDisabled,
  loading,
  className,
  ...rest
}) => {
  const classes = useStyles();
  const [modal, setModal] = useState<{ visible: boolean; fileId: string; index: number }>({
    visible: false,
    fileId: null,
    index: null
  });
  const files = attachments ? [...values, ...attachments] : values;
  const usedLabels = multiple ? labels.multi : labels.single;
  const disabled = (!multiple && !!files.length) || isDisabled;

  const handleDelete = (index: number, fileId: string) => () => {
    if (!fileId) {
      onDelete(index, null);
    } else {
      setModal({ visible: true, fileId, index });
    }
  };

  const cancelDelete = () => {
    setModal({ visible: false, fileId: null, index: null });
  };

  const approveDelete = () => {
    onDelete(modal.index, modal.fileId);
    setModal({ visible: false, fileId: null, index: null });
  };

  const onClick = (index: number) => () => {
    const win = window.open(`${API_SUFFIX}/files/${files[index].id}`, '_blank');
    win.focus();
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: multiple ?? false,
    disabled
  });

  const getSubtitle = (file: UploadedFile) => {
    const date = formatDateUtil(file?.created?.toString());
    const author = file.author || '';
    const size = file.size ? bytesToSize(file.size) : '';
    return `${date} - ${author} (${size})`;
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <div
        className={clsx({
          [classes.dropZone]: true,
          [classes.dragActive]: isDragActive,
          [classes.disabled]: disabled
        })}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <div>
          <img alt="Select file" className={classes.image} src="/static/images/undraw_add_file2_gvbb.svg" />
        </div>
        <div>
          <Typography variant="h3">{usedLabels.title}</Typography>
          <Box mt={2}>
            <Typography color="textPrimary">
              {usedLabels.description} sau <Link underline="always">click</Link>!
            </Typography>
          </Box>
        </div>
        {loading && (
          <div className={classes.loading}>
            <CircularProgress size={50} />
          </div>
        )}
      </div>
      {files.length > 0 && (
        <>
          <PerfectScrollbar options={{ suppressScrollX: true }}>
            <List className={classes.list}>
              {files.map((file, i) => (
                <ListItem divider={i < files.length - 1} key={i}>
                  <ListItemIcon>
                    <FileCopyIcon />
                  </ListItemIcon>
                  <ListItemText
                    className={clsx({
                      [classes.redirectText]: !!file.uri
                    })}
                    primary={file.name || file.originalName}
                    primaryTypographyProps={{ variant: 'h5' }}
                    secondary={getSubtitle(file)}
                    onClick={file.id && !loading && onClick(i)}
                  />
                  {onDelete && (
                    <Tooltip title="Stergere">
                      <IconButton edge="end" onClick={handleDelete(i, file.id)} disabled={loading || isDisabled}>
                        <SvgIcon color={isDisabled ? 'disabled' : 'error'}>
                          <DeleteIcon />
                        </SvgIcon>
                      </IconButton>
                    </Tooltip>
                  )}
                </ListItem>
              ))}
            </List>
          </PerfectScrollbar>
          <Dialog
            visible={modal.visible}
            title="STERGERE"
            question="Doriti stergerea fisierului?"
            actionTitle="Sterge"
            cancel={cancelDelete}
            action={approveDelete}
          />
        </>
      )}
    </div>
  );
};

FilesDropzone.propTypes = {
  className: PropTypes.string
};

export default FilesDropzone;
