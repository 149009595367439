import moment from 'moment';

export const DATE_FORMAT = 'DD.MM.YYYY';

const formatDateUtil = (value: string | Date, onlyDate?: boolean) => {
  if (!value) return '';
  if (onlyDate) return moment(value).format('DD.MM.YYYY');
  return moment(value).format('DD.MM.YYYY HH:mm');
};

export const formatShortDateUtil = (value: string) => {
  return new Date(value).toISOString().split('T')[0];
};

export const formatLongDateUtil = (value: string) => moment(value).format('LLL');

export default formatDateUtil;
