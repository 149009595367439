import PropTypes from 'prop-types';
import React, { FC } from 'react';
import Form from 'src/components/Form';
import { Email as Model } from 'src/types/email';
import * as Yup from 'yup';

import { Box, CircularProgress, Link, Typography } from '@material-ui/core';

interface Props {
  className?: string;
  entity: Model;
  attachment?: string;
  action: (values: any) => Promise<void | string | object>;
  labels: {
    cardTitle: string;
    submitLabel: string;
    messages: {
      success: string;
      fail: string;
    };
  };
  attachmentLoading?: boolean;
  cardHeaderAction?: any;
  freezed?: boolean;
}

const EntityEditForm: FC<Props> = ({
  entity,
  action,
  labels,
  attachment,
  attachmentLoading,
  cardHeaderAction,
  freezed
}) => {
  const renderAttachment = (attachmentId: string) => {
    if (attachmentId || attachmentLoading)
      return (
        <>
          <Typography color="primary">
            Atasament
            <br />
            {attachmentId && (
              <Link
                component="a"
                color="textSecondary"
                underline="always"
                href={`/download/${attachmentId}`}
                target="_blank"
              >
                {entity.title}.pdf
              </Link>
            )}
          </Typography>
          {!attachmentId && attachmentLoading && (
            <Box pt={1}>
              <CircularProgress size={24} />
            </Box>
          )}
        </>
      );
    return null;
  };

  return (
    <Form
      fields={[
        {
          label: 'De la',
          name: 'from',
          validation: Yup.string().required('Camp obligatoriu!'),
          // disabled: true,
          initialValue: entity?.from,
          pos: { lg: 12, md: 12, sm: 12, xs: 12 }
        },
        {
          label: 'Catre',
          name: 'to',
          validation: Yup.string().required('Camp obligatoriu!'),
          initialValue: entity?.to,
          pos: { lg: 12, md: 12, sm: 12, xs: 12 }
        },
        {
          label: 'CC',
          name: 'cc',
          initialValue: entity?.cc,
          pos: { lg: 12, md: 12, sm: 12, xs: 12 }
        },
        {
          label: 'Subiect',
          name: 'subject',
          validation: Yup.string().required('Camp obligatoriu!'),
          initialValue: entity?.subject,
          pos: { lg: 12, md: 12, sm: 12, xs: 12 }
        },
        {
          label: 'Titlu',
          name: 'title',
          validation: Yup.string().required('Camp obligatoriu!'),
          initialValue: entity?.title,
          pos: { lg: 12, md: 12, sm: 12, xs: 12 }
        },
        {
          label: 'Continut',
          name: 'content',
          validation: Yup.string().required('Camp obligatoriu!'),
          initialValue: entity?.content,
          pos: { lg: 12, md: 12, sm: 12, xs: 12 },
          props: {
            multiline: true
          }
        },
        {
          label: 'Subsol',
          name: 'footer',
          validation: Yup.string().required('Camp obligatoriu!'),
          initialValue: entity?.footer,
          pos: { lg: 12, md: 12, sm: 12, xs: 12 },
          props: {
            multiline: true
          }
        }
      ]}
      messages={labels.messages}
      action={action}
      actionDisabled={attachmentLoading}
      submitLabel={labels.submitLabel}
      cardTitle={labels.cardTitle}
      error={!entity}
      cardHeaderAction={cardHeaderAction}
      spacing={3}
      footer={renderAttachment(attachment)}
      freezed={freezed}
    />
  );
};

EntityEditForm.propTypes = {
  className: PropTypes.string
};

export default EntityEditForm;
