import { useContext } from 'react';
import GlobalModalContext from 'src/contexts/GlobalModalContext';
import EmailModal from 'src/pages/emails/EmailModal';

export enum MODALS {
  EMAIL,
  DOCUMENT_CREATE,
  DOCUMENT_REGENERATE,
  ADDITION_CREATE,
  BENEFICIARY_CREATE,
  BROKER_CREATE,
  CLIENT_CREATE
}

export const MODALS_COMP = {
  [MODALS.EMAIL]: EmailModal
};

const useGlobalModal = () => useContext(GlobalModalContext);

export default useGlobalModal;
