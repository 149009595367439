import PropTypes from 'prop-types';
import React from 'react';
import { Redirect } from 'react-router-dom';

import useAuth from '../hooks/useAuth';

import type { FC, ReactNode } from 'react';
interface NoAccessGuardProps {
  children?: ReactNode;
}

const NoAccessGuard: FC<NoAccessGuardProps> = ({ children }) => {
  const { isAuthenticated, connectEmail, user } = useAuth();

  if (isAuthenticated && user?.hasAccess) {
    return <Redirect to="/app" />;
  } else if (!connectEmail) {
    return <Redirect to="/connect" />;
  }

  return <>{children}</>;
};

NoAccessGuard.propTypes = {
  children: PropTypes.node
};

export default NoAccessGuard;
