import jwtDecode from 'jwt-decode';
import React, { createContext, useEffect, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import SplashScreen from 'src/components/SplashScreen';
import axios from 'src/utils/axios';

import type { FC, ReactNode } from 'react';
import type { USER_ROLES, User } from 'src/types/user';

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  user: User | null;
  role: USER_ROLES | null;
  budgetLink: string | null;
  isNew: boolean;
  connectEmail: string;
}

interface AuthContextValue extends AuthState {
  method: 'JWT';
  connect: (email: string) => Promise<void>;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  register: (email: string, firstName: string, lastName: string, password: string) => Promise<void>;
  updateUser: (user: User) => Promise<void>;
  clear: () => void;
  forgotPassword: (email: string) => Promise<void>;
  resetPassword: (password: string, token: string) => Promise<void>;
  setNewPassword: (oldPassword: string, newPassword: string) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitialiseAction = {
  type: 'INITIALISE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
  };
};

type ConnectAction = {
  type: 'CONNECT';
  payload: {
    isNew: boolean;
    connectEmail: string;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: User;
  };
};

type UpdateUserAction = {
  type: 'UPDATE_USER';
  payload: {
    user: User;
  };
};

type ClearAction = {
  type: 'CLEAR';
  payload: {
    isNew: boolean;
    connectEmail: string;
  };
};

type SetNewPasswordAction = {
  type: 'SET_NEW_PASSWORD';
  payload: {
    user: User;
  };
};

type Action =
  | InitialiseAction
  | LoginAction
  | LogoutAction
  | RegisterAction
  | UpdateUserAction
  | ConnectAction
  | ClearAction
  | SetNewPasswordAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
  role: null,
  budgetLink: null,
  isNew: true,
  connectEmail: localStorage.getItem('connectEmail')
};

const isValidToken = (accessToken: string): boolean => {
  if (!accessToken) {
    return false;
  }

  const decoded: any = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (accessToken: string | null): void => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const setConnectEmail = (email: string | null): void => {
  if (email) {
    localStorage.setItem('connectEmail', email);
  } else {
    localStorage.removeItem('connectEmail');
  }
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload;
      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
        role: user?.role,
        budgetLink: user?.budgetLink
      };
    }
    case 'CONNECT': {
      const { isNew, connectEmail } = action.payload;
      return {
        ...state,
        isNew,
        connectEmail
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
        role: user?.role
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        role: null
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
        role: user?.role
      };
    }
    case 'UPDATE_USER': {
      const { user } = action.payload;

      return {
        ...state,
        user,
        role: user?.role
      };
    }
    case 'CLEAR': {
      return {
        ...state,
        isNew: true,
        connectEmail: null
      };
    }
    case 'SET_NEW_PASSWORD': {
      const { user } = action.payload;

      return {
        ...state,
        user,
        role: user?.role
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => {},
  register: () => Promise.resolve(),
  connect: () => Promise.resolve(),
  clear: () => {},
  updateUser: () => Promise.resolve(),
  forgotPassword: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
  setNewPassword: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const history = useHistory();

  const connect = async (email: string) => {
    const response = await axios.post<{ isNew: boolean }>('/api/auth/check', {
      email
    });
    const { isNew } = response.data;
    setConnectEmail(email);
    dispatch({
      type: 'CONNECT',
      payload: {
        isNew,
        connectEmail: email
      }
    });
    if (isNew) {
      history.push('/register');
    } else {
      history.push('/login');
    }
  };

  const clear = () => {
    setConnectEmail(null);
    dispatch({
      type: 'CLEAR',
      payload: {
        isNew: false,
        connectEmail: null
      }
    });
  };

  const login = async (email: string, password: string) => {
    const response = await axios.post<{ token: string; user: User }>('/api/auth/login', { email, password });
    const { token, user } = response.data;
    setSession(token);
    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (email: string, firstName: string, lastName: string, password: string) => {
    const response = await axios.post<{ token: string; user: User }>('/api/auth/register', {
      email,
      firstName,
      lastName,
      password
    });
    const { token, user } = response.data;
    setSession(token);

    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  const updateUser = async (changedUser: User) => {
    const response = await axios.patch<User>('/api/users/me', changedUser);
    const user = response.data;

    dispatch({
      type: 'UPDATE_USER',
      payload: {
        user
      }
    });
  };

  const forgotPassword = async (email: string) => {
    await axios.post<{ email: string }>('/api/auth/forgot-password', { email });
  };

  const resetPassword = async (password: string, resetPasswordToken: string) => {
    await axios.post('/api/auth/reset-password', { password, resetPasswordToken });
  };

  const setNewPassword = async (oldPassword: string, newPassword: string) => {
    const response = await axios.post('/api/auth/set-new-password', { oldPassword, newPassword });
    const { token, user } = response.data;
    setSession(token);
    dispatch({
      type: 'SET_NEW_PASSWORD',
      payload: {
        user
      }
    });
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get<User>('/api/users/me');
          const user = response.data;
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register,
        connect,
        clear,
        updateUser,
        forgotPassword,
        resetPassword,
        setNewPassword
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
