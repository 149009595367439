import { AxiosResponse } from 'axios';
// import { generatePath } from 'react-router';
import { ActivityLog } from 'src/types/activityLog';
import { Article } from 'src/types/article';
import { Attend } from 'src/types/attend';
import { Email } from 'src/types/email';
import { UploadedFile } from 'src/types/file';
import { PaginatedQuery } from 'src/types/paginatedQuery';
import { User } from 'src/types/user';
import axios from 'src/utils/axios';

enum METHODS {
  GET,
  POST,
  PATCH,
  DELETE
}

export const API_SUFFIX = '/api';

async function call<T>(method: METHODS, endpoint: string, suffix?: string, body?: unknown, query?: unknown) {
  const url = endpoint + (suffix ? `/${suffix}` : '') + (query ? `${query}` : '');
  let response: AxiosResponse<T>;
  switch (method) {
    case METHODS.GET:
      response = await axios.get<T>(url);
      break;
    case METHODS.PATCH:
      response = await axios.patch<T>(url, body);
      break;
    case METHODS.POST:
      response = await axios.post<T>(url, body);
      break;
    case METHODS.DELETE:
      response = await axios.delete<T>(url);
      break;
  }
  return response.data;
}

function getAction<Model>(endpoint: string) {
  return (id?: string) => call<Model>(METHODS.GET, endpoint, id);
}

// function customGet<Model>(endpoint: string) {
//   return (params: { [paramName: string]: string | number | boolean }) =>
//     call<Model>(METHODS.GET, generatePath(endpoint, params));
// }

function listAction<Model>(endpoint: string) {
  return (id?: string, query?: string) => call<{ result: Model[] }>(METHODS.GET, endpoint, id, null, query);
}

function listPaginatedAction<Model>(endpoint: string) {
  return (query?: PaginatedQuery) => {
    let parsedEndpoint = endpoint;
    if (query) {
      parsedEndpoint += '?';
      Object.keys(query).forEach((key: string) => {
        let value = query[key];
        if (value !== undefined) {
          if (typeof query[key] === 'object') {
            value = JSON.stringify(query[key]);
          }
          parsedEndpoint += `${key}=${value}&`;
        }
      });
      parsedEndpoint = parsedEndpoint.substr(0, parsedEndpoint.length - 1);
    }
    return call<{ result: Model[]; total: number; skip: number; limit: number }>(METHODS.GET, parsedEndpoint);
  };
}

function updateAction<Model>(endpoint: string) {
  return (id?: string, entity?: Partial<Model>) => call<Model>(METHODS.PATCH, endpoint, id, entity);
}

// function customUpdate<Model>(endpoint: string) {
//   return (params: { [paramName: string]: string | number | boolean }, entity?: Model) =>
//     call<Model>(METHODS.PATCH, generatePath(endpoint, params), null, entity);
// }

function createAction<Model>(endpoint: string) {
  return (entity?: Model) => call<Model>(METHODS.POST, endpoint, null, entity);
}

// function customCreate<Model>(endpoint: string) {
//   return (params: { [paramName: string]: string | number | boolean }, entity?: Model) =>
//     call<Model>(METHODS.POST, generatePath(endpoint, params), null, entity);
// }

function deleteAction(endpoint: string) {
  return (id?: string) => call<{ success: boolean }>(METHODS.DELETE, endpoint, id);
}

// function customDelete(endpoint: string) {
//   return (params: { [paramName: string]: string | number | boolean }) =>
//     call(METHODS.DELETE, generatePath(endpoint, params));
// }

function crudGenerator<Model>(endpoint) {
  return {
    get: getAction<Model>(endpoint),
    list: listAction<Model>(endpoint),
    listPg: listPaginatedAction<Model>(endpoint),
    update: updateAction<Model>(endpoint),
    create: createAction<Model>(endpoint),
    delete: deleteAction(endpoint)
  };
}

const ENDPOINTS: { [key: string]: { [key: string]: string } } = {
  users: {
    base: `${API_SUFFIX}/users`,
    list: `${API_SUFFIX}/users/list`,
    budget: `${API_SUFFIX}/users/budget`
  },
  files: {
    base: `${API_SUFFIX}/files`,
    check: `${API_SUFFIX}/files/check`
  },
  emails: {
    base: `${API_SUFFIX}/emails`
  },
  activityLog: {
    base: `${API_SUFFIX}/activity-log`
  },
  articles: {
    base: `${API_SUFFIX}/articles`
  },
  attends: {
    base: `${API_SUFFIX}/attends`,
    user: `${API_SUFFIX}/attends/user`,
    users: `${API_SUFFIX}/attends/users`
  }
};

export default {
  users: {
    get: getAction<User>(ENDPOINTS.users.base),
    list: listAction<User>(ENDPOINTS.users.list),
    update: updateAction<User>(ENDPOINTS.users.base),
    budget: getAction<any>(ENDPOINTS.users.budget)
  },
  files: {
    ...crudGenerator<UploadedFile>(ENDPOINTS.files.base),
    check: getAction<{ title: string; id: string }>(ENDPOINTS.files.check)
  },
  emails: {
    ...crudGenerator<Email>(ENDPOINTS.emails.base)
  },
  activityLog: {
    listPg: listPaginatedAction<ActivityLog>(ENDPOINTS.activityLog.base)
  },
  articles: {
    ...crudGenerator<Article>(ENDPOINTS.articles.base)
  },
  attends: {
    ...crudGenerator<Attend>(ENDPOINTS.attends.base),
    user: getAction<Attend[]>(ENDPOINTS.attends.user),
    users: getAction<Attend[]>(ENDPOINTS.attends.users)
  }
};
