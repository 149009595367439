import type { FC } from 'react';
import React from 'react';

interface LogoProps {
  [key: string]: any;
}

const Logo: FC<LogoProps> = (props) => {
  if (props.withWrapper)
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 40 }}>
        <img alt="Logo" src="/static/logo.png" style={{ width: props.width || 200 }} {...props} />
      </div>
    );

  return <img alt="Logo" src="/static/logo.png" style={{ width: props.width || 200 }} {...props} />;
};

export default Logo;
